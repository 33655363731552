import projectsImage from "../projects copy.jpg"
import background from "../iso-republic-textured-grey-fabric copy.jpg"
import { useEffect } from "react"
import p1 from "../projectPhotos/IMG_2352.JPG"
import p2 from "../projectPhotos/IMG_2353.JPG"
import p3 from "../projectPhotos/IMG_2354.JPG"
import p4 from "../projectPhotos/IMG_2355.JPG"
import p5 from "../projectPhotos/IMG_2356.JPG"
import p6 from "../projectPhotos/IMG_2357.JPG"
import p7 from "../projectPhotos/IMG_5967.jpg"
import p8 from "../projectPhotos/IMG_5968.jpg"


const Projects = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="bg-cover bg-center md:h-200  bg-origin-border	bg-fixed flex flex-col items-center m:0 md:mt-15 pt-5 overflow-hidden" style={{ backgroundImage: `url(${background})` }}>
            <div className="animation w-screen flex justify-center ">
                <img src={projectsImage} />
            </div>
            <div className="flex justify-around text-center text-xl flex-col items-center animation">
                <section className=" bg-black text-white rounded p-5 m-5">
                    <p className="text-3xl">Recent Work</p>
                </section>
                <div className="w-50 grid grid-cols-2 p-2 m-2 gap-2 mb-5 md:mb-10">
                    <img src={p7} className=" img  projectImg" />
                    <img src={p8} className=" img  projectImg" />
                    <img src={p3} className=" img  projectImg" />
                    <img src={p2} className=" img  projectImg" />
                    <img src={p1} className=" img  projectImg" />
                    <img src={p4} className=" img  projectImg" />
                    <img src={p5} className=" img  projectImg" />
                    <img src={p6} className=" img  projectImg" />
                </div>
            </div>
        </div>
    )

}

export default Projects;